import React, { Component } from 'react';
import dynamic from 'next/dynamic';
import { inject, observer } from 'mobx-react';
// import Dashboard from 'components/dashboard';

const NoSSRDashboard = dynamic(() => import('../components/dashboard'), {
  ssr: false,
});

@inject('mobxStore')
@observer
class DashboardPage extends Component {
  componentDidMount() {
    if (!this.props.mobxStore.authStore.isAuthenticated) {
      const { host } = window.location;
      const isDev = host.includes('dev');
      const devPref = isDev ? '-dev' : '';
      // Router.pushRoute('/login');
      if (window.location.href.includes('/uk')) {
        window.location.href = `https://account${devPref}.plume.com/login?cloud=${isDev ? 'ci' : 'kappa'}&client_id=plumeWebStore&redirect_uri=https:%2F%2F${host}%2Fuk`;
      } else if (window.location.href.includes('/de')) {
        window.location.href = `https://account${devPref}.plume.com/login?cloud=${isDev ? 'ci' : 'kappa'}&client_id=plumeWebStore&redirect_uri=https:%2F%2F${host}%2Fde`;
      } else if (window.location.href.includes('/be')) {
        window.location.href = `https://account${devPref}.plume.com/login?cloud=${isDev ? 'ci' : 'kappa'}&client_id=plumeWebStore&redirect_uri=https:%2F%2F${host}%2Fbe`;
      } else if (window.location.href.includes('/cy')) {
        window.location.href = `https://account${devPref}.plume.com/login?cloud=${isDev ? 'ci' : 'kappa'}&client_id=plumeWebStore&redirect_uri=https:%2F%2F${host}%2Fcy`;
      } else {
        window.location.href = `https://account${devPref}.plume.com/login?cloud=${isDev ? 'ci' : 'gamma'}&client_id=plumeWebStore&redirect_uri=https:%2F%2F${host}%2Fus`;
      }
      // window.location.href = 'https://account.plume.com/login?cloud=ci&client_id=plumeWebStore&redirect_uri=http:%2F%2Flocalhost:3000';
    }
  }

  render() {
    const {
      mobxStore: {
        authStore: {
          isAuthenticated,
        },
      },
    } = this.props;

    if (!isAuthenticated) {
      return null;
    }

    return <NoSSRDashboard />;
  }
}

export default DashboardPage;
